<template>
  <sm-page-loader v-if="isLoadingPage" />
  <div v-else class="debtor-list">
    <h2 class="editable-item__header">
      {{ pageTitle }}
    </h2>
    <sm-breadcrumbs class="debtor-list__breadcrumbs" :items="breadcrumbs" />

    <div class="debtor-list__button-wrapper">
      <sm-button
        class="debtor-list__button"
        @click="openConfirmModal"
      >
        Оповестить должников по СМС
      </sm-button>
    </div>
    
    <div
      v-if="isError"
      class="notifications__container debtors-list__error-container"
    >
      <sm-notification-message
        header="Ошибка"
        type="error"
        :text="error"
        @hide="handleCloseError"
      />
    </div>

    <sm-report :reportName="reportName" />

    <sm-modal
      :show="isConfirmModal"
      modalTitle="Внимание!"
      @close="closeConfirmModal"
    >
      <template #body>
        <div class="debtor-list__modal-content">
          <p class="debtor-list__message">
            Подтвердите запуск оповещения должников.
          </p>
          <div class="debtor-list__modal-footer">
            <sm-button class="debtor-list__button" @click="handleConfirm">
              Подтвердить
            </sm-button>
            <sm-button class="debtor-list__button" @click="closeConfirmModal">
              Отменить
            </sm-button>
          </div>
        </div>
      </template>
    </sm-modal>
  </div>
</template>

<script>
// vuex
import { mapActions } from 'vuex';
// components
import SmBreadcrumbs from '@/components/common/breadcrumbs/SmBreadcrumbs.vue';
import SmButton from '@/components/common/buttons/SmButton.vue';
import SmModal from '@/components/common/modals/SmModal.vue';
import SmNotificationMessage from '@/components/common/notification/SmNotificationMessage.vue';
import SmReport from '@/components/views/SmReport.vue';
import SmPageLoader from '@/components/common/SmPageLoader.vue';

export default {
  name: 'DebtorList',

  components: {
    SmBreadcrumbs,
    SmButton,
    SmModal,
    SmNotificationMessage,
    SmReport,
    SmPageLoader,
  },

  data() {
    return {      
      reportName: 'CustomersDebtsNotificationReport',
      pageTitle: 'Список должников',
      breadcrumbs: [
        {
          text: 'Клиенты',
          route: { name: 'CustomersMain' },
        },
        {
          text: 'Список должников',
        },
      ],
      isError: false,
      error: '',
      isLoadingPage: false,
      isConfirmModal: false,
    };
  },

  methods: {
    ...mapActions({
      notificationAboutDebt: 'debtors/notificationAboutDebt',
    }),

    openConfirmModal() {
      this.isConfirmModal = true;
    },

    closeConfirmModal() {
      this.isConfirmModal = false;
    },

    handleConfirm() {
      this.handleDebtors(true);
      this.closeConfirmModal();
    },

    async handleDebtors(isNeedSendSms = false) {
      this.isLoadingPage = true;
      try {
        await this.notificationAboutDebt(isNeedSendSms);
      } catch (error) {
        this.error = error;
        this.isError = true;
      } finally {
        this.isLoadingPage = false;
      }
    },

    handleCloseError() {
      this.isError = false;
      this.error = '';
    },
  },
};
</script>

<style lang="scss">
.editable-item__header {
  margin-bottom: 30px;
}

.debtor-list__breadcrumbs {
  margin-bottom: 15px;
}

.debtor-list__button-wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 0 15px;
  gap: 20px;
  width: 100%;
  margin-bottom: 15px;
}

.debtor-list__button {
  width: 180px;
}

.debtor-list__modal-footer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  justify-content: center;
}
</style>